export const countries = [
  { value: "Afghanistan", text: "Afghanistan" },
  { value: "Albania", text: "Albania" },
  { value: "Algeria", text: "Algeria" },
  { value: "Andorra", text: "Andorra" },
  { value: "Angola", text: "Angola" },
  { value: "Antigua and Barbuda", text: "Antigua and Barbuda" },
  { value: "Argentina", text: "Argentina" },
  { value: "Armenia", text: "Armenia" },
  { value: "Australia", text: "Australia" },
  { value: "Austria", text: "Austria" },
  { value: "Azerbaijan", text: "Azerbaijan" },
  { value: "Bahamas", text: "Bahamas" },
  { value: "Bahrain", text: "Bahrain" },
  { value: "Bangladesh", text: "Bangladesh" },
  { value: "Barbados", text: "Barbados" },
  { value: "Belarus", text: "Belarus" },
  { value: "Belgium", text: "Belgium" },
  { value: "Belize", text: "Belize" },
  { value: "Benin", text: "Benin" },
  { value: "Bhutan", text: "Bhutan" },
  { value: "Bolivia", text: "Bolivia" },
  { value: "Bosnia and Herzegovina", text: "Bosnia and Herzegovina" },
  { value: "Botswana", text: "Botswana" },
  { value: "Brazil", text: "Brazil" },
  { value: "Brunei Darussalam", text: "Brunei Darussalam" },
  { value: "Bulgaria", text: "Bulgaria" },
  { value: "Burkina Faso", text: "Burkina Faso" },
  { value: "Burundi", text: "Burundi" },
  { value: "Cambodia", text: "Cambodia" },
  { value: "Cameroon", text: "Cameroon" },
  { value: "Canada", text: "Canada" },
  { value: "Cape Verde", text: "Cape Verde" },
  { value: "Central African Republic", text: "Central African Republic" },
  { value: "Chad", text: "Chad" },
  { value: "Chile", text: "Chile" },
  { value: "China", text: "China" },
  { value: "Colombia", text: "Colombia" },
  { value: "Comoros", text: "Comoros" },
  { value: "Congo (Republic of the)", text: "Congo (Republic of the)" },
  { value: "Costa Rica", text: "Costa Rica" },
  { value: "Côte d’Ivoire", text: "Côte d’Ivoire" },
  { value: "Croatia", text: "Croatia" },
  { value: "Cuba", text: "Cuba" },
  { value: "Cyprus", text: "Cyprus" },
  { value: "Czech Republic", text: "Czech Republic" },
  { value: "Democratic People’s Republic of Korea", text: "Democratic People’s Republic of Korea" },
  { value: "Democratic Republic of the Congo", text: "Democratic Republic of the Congo" },
  { value: "Denmark", text: "Denmark" },
  { value: "Djibouti", text: "Djibouti" },
  { value: "Dominica", text: "Dominica" },
  { value: "Dominican Republic", text: "Dominican Republic" },
  { value: "Ecuador", text: "Ecuador" },
  { value: "Egypt", text: "Egypt" },
  { value: "El Salvador", text: "El Salvador" },
  { value: "Equatorial Guinea", text: "Equatorial Guinea" },
  { value: "Eritrea", text: "Eritrea" },
  { value: "Estonia", text: "Estonia" },
  { value: "Ethiopia", text: "Ethiopia" },
  { value: "Fiji", text: "Fiji" },
  { value: "Finland", text: "Finland" },
  { value: "France", text: "France" },
  { value: "Gabon", text: "Gabon" },
  { value: "Gambia", text: "Gambia" },
  { value: "Georgia", text: "Georgia" },
  { value: "Germany", text: "Germany" },
  { value: "Greece", text: "Greece" },
  { value: "Grenada", text: "Grenada" },
  { value: "Guatemala", text: "Guatemala" },
  { value: "Guinea", text: "Guinea" },
  { value: "Guinea-Bissau", text: "Guinea-Bissau" },
  { value: "Guyana", text: "Guyana" },
  { value: "Haiti", text: "Haiti" },
  { value: "Honduras", text: "Honduras" },
  { value: "Hungary", text: "Hungary" },
  { value: "Iceland", text: "Iceland" },
  { value: "India", text: "India" },
  { value: "Indonesia", text: "Indonesia" },
  { value: "Iran", text: "Iran" },
  { value: "Iraq", text: "Iraq" },
  { value: "Ireland", text: "Ireland" },
  { value: "Israel", text: "Israel" },
  { value: "Italy", text: "Italy" },
  { value: "Jamaica", text: "Jamaica" },
  { value: "Japan", text: "Japan" },
  { value: "Jordan", text: "Jordan" },
  { value: "Kazakhstan", text: "Kazakhstan" },
  { value: "Kenya", text: "Kenya" },
  { value: "Kiribati", text: "Kiribati" },
  { value: "Kuwait", text: "Kuwait" },
  { value: "Kyrgyzstan", text: "Kyrgyzstan" },
  { value: "Lao People’s Democratic Republic", text: "Lao People’s Democratic Republic" },
  { value: "Latvia", text: "Latvia" },
  { value: "Lebanon", text: "Lebanon" },
  { value: "Lesotho", text: "Lesotho" },
  { value: "Liberia", text: "Liberia" },
  { value: "Libya", text: "Libya" },
  { value: "Liechtenstein", text: "Liechtenstein" },
  { value: "Lithuania", text: "Lithuania" },
  { value: "Luxembourg", text: "Luxembourg" },
  { value: "Madagascar", text: "Madagascar" },
  { value: "Malawi", text: "Malawi" },
  { value: "Malaysia", text: "Malaysia" },
  { value: "Maldives", text: "Maldives" },
  { value: "Mali", text: "Mali" },
  { value: "Malta", text: "Malta" },
  { value: "Marshall Islands", text: "Marshall Islands" },
  { value: "Mauritania", text: "Mauritania" },
  { value: "Mauritius", text: "Mauritius" },
  { value: "Mexico", text: "Mexico" },
  { value: "Micronesia (Federated States of)", text: "Micronesia (Federated States of)" },
  { value: "Monaco", text: "Monaco" },
  { value: "Mongolia", text: "Mongolia" },
  { value: "Montenegro", text: "Montenegro" },
  { value: "Morocco", text: "Morocco" },
  { value: "Mozambique", text: "Mozambique" },
  { value: "Myanmar", text: "Myanmar" },
  { value: "Namibia", text: "Namibia" },
  { value: "Nauru", text: "Nauru" },
  { value: "Nepal", text: "Nepal" },
  { value: "Netherlands", text: "Netherlands" },
  { value: "New Zealand", text: "New Zealand" },
  { value: "Nicaragua", text: "Nicaragua" },
  { value: "Niger", text: "Niger" },
  { value: "Nigeria", text: "Nigeria" },
  { value: "Norway", text: "Norway" },
  { value: "Oman", text: "Oman" },
  { value: "Pakistan", text: "Pakistan" },
  { value: "Palau", text: "Palau" },
  { value: "Panama", text: "Panama" },
  { value: "Papua New Guinea", text: "Papua New Guinea" },
  { value: "Paraguay", text: "Paraguay" },
  { value: "Peru", text: "Peru" },
  { value: "Philippines", text: "Philippines" },
  { value: "Poland", text: "Poland" },
  { value: "Portugal", text: "Portugal" },
  { value: "Qatar", text: "Qatar" },
  { value: "Republic of Korea", text: "Republic of Korea" },
  { value: "Republic of Moldova", text: "Republic of Moldova" },
  { value: "Romania", text: "Romania" },
  { value: "Russian Federation", text: "Russian Federation" },
  { value: "Rwanda", text: "Rwanda" },
  { value: "Saint Kitts and Nevis", text: "Saint Kitts and Nevis" },
  { value: "Saint Lucia", text: "Saint Lucia" },
  { value: "Saint Vincent and the Grenadines", text: "Saint Vincent and the Grenadines" },
  { value: "Samoa", text: "Samoa" },
  { value: "San Marino", text: "San Marino" },
  { value: "Sao Tome and Principe", text: "Sao Tome and Principe" },
  { value: "Saudi Arabia", text: "Saudi Arabia" },
  { value: "Senegal", text: "Senegal" },
  { value: "Serbia", text: "Serbia" },
  { value: "Seychelles", text: "Seychelles" },
  { value: "Sierra Leone", text: "Sierra Leone" },
  { value: "Singapore", text: "Singapore" },
  { value: "Slovakia", text: "Slovakia" },
  { value: "Slovenia", text: "Slovenia" },
  { value: "Solomon Islands", text: "Solomon Islands" },
  { value: "Somalia", text: "Somalia" },
  { value: "South Africa", text: "South Africa" },
  { value: "South Sudan", text: "South Sudan" },
  { value: "Spain", text: "Spain" },
  { value: "Sri Lanka", text: "Sri Lanka" },
  { value: "Sudan", text: "Sudan" },
  { value: "Suriname", text: "Suriname" },
  { value: "Swaziland", text: "Swaziland" },
  { value: "Switzerland", text: "Switzerland" },
  { value: "Sweden", text: "Sweden" },
  { value: "Syria", text: "Syria" },
  { value: "Tajikistan", text: "Tajikistan" },
  { value: "Thailand", text: "Thailand" },
  { value: "The former Yugoslav Republic of Macedonia", text: "The former Yugoslav Republic of Macedonia" },
  { value: "Timor Leste", text: "Timor Leste" },
  { value: "Togo", text: "Togo" },
  { value: "Tonga", text: "Tonga" },
  { value: "Trinidad and Tobago", text: "Trinidad and Tobago" },
  { value: "Tunisia", text: "Tunisia" },
  { value: "Turkey", text: "Turkey" },
  { value: "Turkmenistan", text: "Turkmenistan" },
  { value: "Tuvalu", text: "Tuvalu" },
  { value: "Uganda", text: "Uganda" },
  { value: "Ukraine", text: "Ukraine" },
  { value: "United Arab Emirates", text: "United Arab Emirates" },
  { value: "United Kingdom", text: "United Kingdom" },
  { value: "United of Republic of Tanzania", text: "United of Republic of Tanzania" },
  { value: "United States", text: "United States" },
  { value: "Uruguay", text: "Uruguay" },
  { value: "Uzbekistan", text: "Uzbekistan" },
  { value: "Vanuatu", text: "Vanuatu" },
  { value: "Venezuela", text: "Venezuela" },
  { value: "Vietnam", text: "Vietnam" },
  { value: "Yemen", text: "Yemen" },
  { value: "Zambia", text: "Zambia" },
  { value: "Zimbabwe", text: "Zimbabwe" }
];

export const states = [
  { value: "AL", text: "AL" },
  { value: "AK", text: "AK" },
  { value: "AZ", text: "AZ" },
  { value: "AR", text: "AR" },
  { value: "CA", text: "CA" },
  { value: "CO", text: "CO" },
  { value: "CT", text: "CT" },
  { value: "DE", text: "DE" },
  { value: "FL", text: "FL" },
  { value: "GA", text: "GA" },
  { value: "HI", text: "HI" },
  { value: "ID", text: "ID" },
  { value: "IL", text: "IL" },
  { value: "IN", text: "IN" },
  { value: "IA", text: "IA" },
  { value: "KS", text: "KS" },
  { value: "KY", text: "KY" },
  { value: "LA", text: "LA" },
  { value: "ME", text: "ME" },
  { value: "MD", text: "MD" },
  { value: "MA", text: "MA" },
  { value: "MI", text: "MI" },
  { value: "MN", text: "MN" },
  { value: "MS", text: "MS" },
  { value: "MO", text: "MO" },
  { value: "MT", text: "MT" },
  { value: "NE", text: "NE" },
  { value: "NV", text: "NV" },
  { value: "NH", text: "NH" },
  { value: "NJ", text: "NJ" },
  { value: "NM", text: "NM" },
  { value: "NY", text: "NY" },
  { value: "NC", text: "NC" },
  { value: "ND", text: "ND" },
  { value: "OH", text: "OH" },
  { value: "OK", text: "OK" },
  { value: "OR", text: "OR" },
  { value: "PA", text: "PA" },
  { value: "RI", text: "RI" },
  { value: "SC", text: "SC" },
  { value: "SD", text: "SD" },
  { value: "TN", text: "TN" },
  { value: "TX", text: "TX" },
  { value: "UT", text: "UT" },
  { value: "VT", text: "VT" },
  { value: "VA", text: "VA" },
  { value: "WA", text: "WA" },
  { value: "WV", text: "WV" },
  { value: "WI", text: "WI" },
  { value: "WY", text: "WY" }
];