var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"table-container",attrs:{"fluid":""}},[_c('b-row',{staticClass:"justify-content-md-center relative"},[_c('loading',{attrs:{"loading":_vm.loading}}),(_vm.extendedData.length)?_c('b-table',{key:_vm.loading,staticClass:"tms-table table",class:_vm.disabled ? 'form-disabled' : '',attrs:{"id":"tmsTable","hover":"","small":"","caption-top":"","responsive":"","sticky-header":"","no-border-collapse":"","items":_vm.extendedData,"fields":_vm.visibleFields,"per-page":_vm.range,"current-page":_vm.currentPage,"thead-class":"header","thead-tr-class":"header__tr brdr-top text-center table-header","tbody-class":"body","tbody-tr-class":_vm.tbodyTrClass,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDescDirection},on:{"row-contextmenu":_vm.onRowContextMenu,"row-clicked":_vm.rowClick},scopedSlots:_vm._u([{key:"thead-top",fn:function(){return [(_vm.tableCategories && _vm.tableCategories.length)?_c('b-tr',{staticClass:"header__tr"},_vm._l((_vm.tableCategories),function(header){return _c('b-th',{key:header.key,staticClass:"text-uppercase collapse-header",class:header.class,attrs:{"colspan":header.collapsed ? header.minColspan : header.maxColspan},on:{"click":function($event){header.opportunityСhange ? _vm.hideFields(header.key) : '',
                (header.collapsed = !header.collapsed)}}},[(header.opportunityСhange && !header.collapsed)?_c('b-icon',{attrs:{"icon":"arrow-down"}}):_vm._e(),_vm._v(" "+_vm._s(header.label)+" "),(header.opportunityСhange && header.collapsed)?_c('b-icon',{staticClass:"collapse-arrow",attrs:{"icon":"arrow-left-right"}}):_vm._e()],1)}),1):_vm._e()]},proxy:true},{key:"cell(number)",fn:function(data){return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(data.value)+" ")])]}},{key:"cell()",fn:function(data){return [(data.field.content)?_c('div',{staticClass:"text-center relative",class:data.field.content.type === _vm.CellContentType.Driver
              ? 'driverCell'
              : ''},[(
              data.field.content.type === _vm.CellContentType.Text ||
              data.field.content.type === _vm.CellContentType.Number
            )?_c('b-form-input',{staticClass:"table-input text-input",class:{ 'no-validate': data.field.content.skipValidation },attrs:{"disabled":data.field.disabledCondition
                ? data.field.disabledCondition(data.item)
                : data.field.disabled,"maxlength":data.field.content.maxLength,"type":data.field.content.type,"size":"sm","state":_vm.validate(
                data.item[data.field.key],
                data.field.content.skipValidation,
                data.field.content.validators
              ),"aria-describedby":"input-text"},on:{"input":function($event){return _vm.onChange(data.item, data.field)}},model:{value:(data.item[data.field.key]),callback:function ($$v) {_vm.$set(data.item, data.field.key, $$v)},expression:"data.item[data.field.key]"}}):_vm._e(),(data.field.content.type === _vm.CellContentType.Text)?_c('b-form-invalid-feedback',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],class:_vm.validate(
                data.item[data.field.key],
                data.field.content.skipValidation,
                data.field.content.validators
              ) === false
                ? 'd-block'
                : 'd-none',attrs:{"title":data.field.content.validatorMessage,"id":"input-text"}}):_vm._e(),(data.field.content.type === _vm.CellContentType.Button)?_c('b-btn',{staticClass:"table-btn text-uppercase",attrs:{"disabled":data.field.disabledCondition
                ? data.field.disabledCondition(data.item)
                : data.field.disabled,"size":"sm"},on:{"click":function($event){return _vm.clickEvent(data.field.key, data.item)}}},[_vm._v(" "+_vm._s(data.field.content.label)+" ")]):_vm._e(),(data.field.content.type === _vm.CellContentType.Icon)?_c('b-btn',{staticClass:"table-btn text-uppercase",class:!data.item[data.field.content.isDisabledBy]
                ? 'icon-disable'
                : '',attrs:{"disabled":data.field.disabled,"size":"sm","title":data.field.content.label},on:{"click":function($event){return _vm.clickEvent(data.field.key, data.item)}}},[_c('b-icon',{attrs:{"icon":data.field.content.icon}})],1):_vm._e(),(data.field.content.type === _vm.CellContentType.AutoComplete)?_c('tms-auto-suggest',{staticClass:"table-input",attrs:{"placeholder":_vm.$t('order.bookingNumber'),"serializer":function (item) { return item.id; },"fetch":function (query) { return _vm.autoComplete(query); },"modelKey":"id","size":"sm","aria-describedby":"input-bookingNumber","transparent":true},on:{"select":function (select) { return (data.item[data.field.key] = select ? select.id : null); }},model:{value:(data.item[data.field.key]),callback:function ($$v) {_vm.$set(data.item, data.field.key, $$v)},expression:"data.item[data.field.key]"}}):_vm._e(),(data.field.content.type === _vm.CellContentType.Date)?_c('tms-date-picker',{staticClass:"table-datepicker td-input",attrs:{"size":"sm","disabled":data.field.disabled ||
              (data.field.content.isDisabledBy &&
                data.field.content.isDisabledBy ===
                  _vm.initialExtendedData[data.index].dropLive),"aria-describedby":"hireDate-feedback","handler-message":data.field.handlerMessage,"handler":data.field.handler,"state":_vm.validate(
                data.item[data.field.key],
                data.field.content.skipValidation
              ),"validation":_vm.validate(
                data.item[data.field.key],
                data.field.content.skipValidation
              ) === false
                ? { message: _vm.$t('validations.required') }
                : null},on:{"input":function($event){return _vm.onChange(data.item, data.field)}},model:{value:(data.item[data.field.key]),callback:function ($$v) {_vm.$set(data.item, data.field.key, $$v)},expression:"data.item[data.field.key]"}}):_vm._e(),(data.field.content.type === _vm.CellContentType.Time)?_c('TimePicker',{key:data.item[data.field.key],staticClass:"td-input",attrs:{"disabled":data.field.disabled ||
              (data.field.content.isDisabledBy &&
                data.field.content.isDisabledBy ===
                  _vm.initialExtendedData[data.index].dropLive),"size":"sm","state":_vm.validate(
                data.item[data.field.key],
                data.field.content.skipValidation
              )},model:{value:(data.item[data.field.key]),callback:function ($$v) {_vm.$set(data.item, data.field.key, $$v)},expression:"data.item[data.field.key]"}}):_vm._e(),(
              data.field.content.type === _vm.CellContentType.Checkbox ||
              data.field.content.type === _vm.CellContentType.Switcher
            )?_c('div',{on:{"click":function($event){$event.stopPropagation();}}},[(
                !data.field.content.conditionalRender ||
                data.field.content.conditionalRender(
                  data.item[data.field.key]
                )
              )?_c('div',[_c('b-form-checkbox',{key:data.field.key + data.item._rowId,staticClass:"text-center",attrs:{"switch":data.field.content.type === _vm.CellContentType.Switcher,"disabled":data.field.disabled ||
                  (data.item.disableFields &&
                    data.item.disableFields[data.field.key]) ||
                  data.item[data.field.key] === 'disabled'},on:{"input":function($event){return _vm.onInputChange(data.field.key, data.item)}},model:{value:(data.item[data.field.key]),callback:function ($$v) {_vm.$set(data.item, data.field.key, $$v)},expression:"data.item[data.field.key]"}})],1):_c('div',[_vm._v(" "+_vm._s(data.item[data.field.key])+" ")])]):_vm._e(),(data.field.content.type === _vm.CellContentType.List)?_c('b-form-select',{staticClass:"td-input",attrs:{"disabled":data.field.disabled,"size":"sm","state":data.field.disabled
                ? null
                : _vm.validate(
                    data.item[data.field.key],
                    data.field.content.skipValidation,
                    data.field.content.validators,
                    data.item
                  ),"options":data.field.content.list,"disabled-field":"disabled","aria-describedby":"input-select"},on:{"change":function($event){return _vm.clickEvent(data.field.key, data.item)}},model:{value:(data.item[data.field.key]),callback:function ($$v) {_vm.$set(data.item, data.field.key, $$v)},expression:"data.item[data.field.key]"}}):_vm._e(),(data.field.content.type === _vm.CellContentType.List)?_c('b-form-invalid-feedback',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],class:_vm.validate(
                data.item[data.field.key],
                data.field.content.skipValidation,
                data.field.content.validators,
                data.item
              ) === false
                ? 'd-block'
                : 'd-none',attrs:{"title":data.field.content.validatorMessage,"id":"input-select"}}):_vm._e(),(data.field.content.type === _vm.CellContentType.Driver)?_c('div',{staticClass:"d-flex flex-column align-items-center",class:(data.field.content.isDisabledBy &&
                data.field.content.isDisabledBy ===
                  _vm.initialExtendedData[data.index].dropLive) ||
              _vm.validate(
                data.item[data.field.key],
                !data.field.content.skipValidation,
                data.field.content.validators,
                data.item
              )
                ? 'disable-events'
                : '',staticStyle:{"height":"100%"},on:{"click":function($event){$event.stopPropagation();}}},[_c('DriverInputWrapper',{class:_vm.isDriverAssignAllowed(
                  data.item,
                  data.field.content.locationType
                )
                  ? 'disabled'
                  : '',attrs:{"uuid":data.item[data.field.content.uuid],"date":data.item[data.field.content.date],"time":data.item[data.field.content.time],"locationType":data.field.content.locationType,"state":_vm.validate(data.item[data.field.key]),"wo":data.item},on:{"updateSchedule":function (items) { return _vm.updateSchedule(data.item, items); }},model:{value:(data.item[data.field.key]),callback:function ($$v) {_vm.$set(data.item, data.field.key, $$v)},expression:"data.item[data.field.key]"}}),(
                _vm.isDriverAssignAllowed(
                  data.item,
                  data.field.content.locationType
                )
              )?_c('b-icon',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:("Can't change or assign driver"),expression:"`Can't change or assign driver`",modifiers:{"hover":true,"top":true}}],attrs:{"icon":"exclamation-circle","font-scale":"1"}}):_vm._e(),(
                data.field.content.type === _vm.CellContentType.Driver &&
                _vm.validate(
                  data.item[data.field.key],
                  !data.field.content.skipValidation,
                  data.field.content.validators,
                  data.item
                )
              )?_c('b-icon',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(data.field.content.validatorMessage),expression:"data.field.content.validatorMessage",modifiers:{"hover":true,"top":true}}],attrs:{"icon":"exclamation-circle","font-scale":"1.1"}}):_vm._e()],1):_vm._e()],1):_c('div',[(
              !(data.item.hideLabels && data.item.hideLabels[data.field.key])
            )?_c('div',{class:_vm.getCellClass(data.field, data.item),on:{"click":function($event){return _vm.clickEvent(data.field.key, data.item)}}},[_vm._v(" "+_vm._s(data.value)+" ")]):_vm._e()])]}}],null,false,1698065484)}):_c('p',{staticClass:"text-center"},[(!_vm.loading)?_c('span',[_vm._v("No data found")]):_vm._e()]),(_vm.extendedData.length && !_vm.hideRowRanges)?_c('div',{staticClass:"d-flex"},[_c('b-form-select',{staticClass:"mr-4",attrs:{"options":_vm.perPageRanges},model:{value:(_vm.range),callback:function ($$v) {_vm.range=$$v},expression:"range"}}),(!_vm.hidePagination)?_c('b-pagination',{attrs:{"total-rows":_vm.rowsAmount,"per-page":_vm.range,"aria-controls":"tmsTable","first-number":"","last-number":""},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }